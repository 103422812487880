.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-color{
  background: #232526;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}
.landing-grid{
  background: #ADA996;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar-img{
  height: 250px;
  padding-top: 5em;
}

.banner-text{
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}
.banner-text h1{
  font-size:66px;
  font-weight: bold;
  color:white;
}

.banner-text hr{
  border-top:5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p{
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links{
  display: text;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i{
  color: white;
  font-size: 5em;
  padding: 8px;
}

.nav-item > .active {
  border-bottom: 1px solid #333;
}

.projects-grid{
  display: flex;
  
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
